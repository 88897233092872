.banner-font-image {
  font-size: 10em;
}

.fa-youtube {
  color: red;
}

.descriptionYoutube {
  font-size: 18px;
}

.buttonYoutube {
  overflow: hidden; height: 24px; width: 170px; border: 0;
}

.divOcult {
 display: none;
}

.divVisible {
  display: block;
}

.titleYoutube {
  color: '#ffffff';
}
