.dateFormat {
  color: #000000;
}

.hourFormat {
    color: #0088cc;
    position: relative;
    display: inline-block;
    font-size: 22px;
}
