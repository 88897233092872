.openContentJob {
   display: block;
}

.box {
	background-color:#EBF0F1;
	padding: 2.25rem 1.0rem;
}

.box + .box {
	margin-top: 2.5rem;
}

.js .inputfile {
   width: 0.1px;
   height: 0.1px;
   opacity: 0;
   overflow: hidden;
   position: absolute;
   z-index: -1;
}

.inputfile + label {
   *max-width: 80%;
   font-size: 1.25rem;
   /* 20px */
   font-weight: 700;
   text-overflow: ellipsis;
   white-space: nowrap;
   cursor: pointer;
   display: inline-block;
   overflow: hidden;
   padding: 0.625rem 1.25rem;
   /* 10px 20px */
}

.no-js .inputfile + label {
   display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
   outline: 1px dotted #000;
   outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
   /* pointer-events: none; */
   /* in case of FastClick lib use */
}

.inputfile + label svg {
   width: 1em;
   height: 1em;
   vertical-align: middle;
   fill: currentColor;
   margin-top: -0.25em;
   /* 4px */
   margin-right: 0.25em;
   /* 4px */
}
/* style 4 */

.inputfile-4 + label {
   color: #076294;
}

.inputfile-4:focus + label,
.inputfile-4.has-focus + label,
.inputfile-4 + label:hover {
   color: #076294;
}

.inputfile-4 + label figure {
   width: 100px;
   height: 100px;
   border-radius: 50%;
   background-color: #076294;
   display: block;
   padding: 20px;
   margin: 0 auto 10px;
}

.inputfile-4:focus + label figure,
.inputfile-4.has-focus + label figure,
.inputfile-4 + label:hover figure {
   background-color: #076294;
}

.inputfile-4 + label svg {
   width: 100%;
   height: 100%;
   fill: #f1e5e6;
}