.backgroundCameras {
  background-color: #292A2B !important;
}

.titleCameras {
  font-size: 31px;
  line-height: 1.5;
  margin: 0 0 20px;
  color: #CAC8C6;
}

.pointsCameras {
  font-size: 17px;
  color: #CAC8C6;
}

.title_partner {
  font-size: 21px;
  line-height: 1.5;
  margin: 0 0 20px;
  color: #CAC8C6;
}

.color_diamond {
  color: #FF0000;
}

.backgroundCamerasDahua {
  background-color: #2980B9 !important;
}

.title_camera_dahua {
  color: #FDFEFE;
  font-size: 31px;
}

.title_partner_dahua {
  color: #FDFEFE;
  font-size: 21px;
}