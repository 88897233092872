.backgroundSubscribeNotification {
  background: #ffffff;
}

.btn-noti-activate {
  background-color: #3498db;
  color: #ffffff;
}

.btn-noti-disabled {
  color: #ffffff;
}
