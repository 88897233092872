.buttonFollow {
  border: 0;
  overflow: hidden;
  width: 213px;
}

.backgroundTwitter {
  background: #171e27;
}

.titleTwitter {
  font-size: 50px;
  color: #ffffff;
}

.contentTwitter {
  font-size: 20px;
  color: #ffffff;
}
