.dateFormat {
  color: #000000;
}

.hourFormat {
    color: #0088cc;
    position: relative;
    display: inline-block;
    font-size: 22px;
}


#timeline {
  list-style: none;
  position: relative;
}
#timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 2px;
  background-color: #4997cd;
  left: 50%;
  margin-left: -1.5px;
}
#timeline .clearFix {
  clear: both;
  height: 0;
}
#timeline .timeline-badge {
  color: #fff;
  width: 50px;
  height: 50px;
  font-size: 1.2em;
  text-align: center;
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: -25px;
  background-color: #4997cd;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
#timeline .timeline-badge span.timeline-balloon-date-day {
  font-size: 1.4em;
}
#timeline .timeline-badge span.timeline-balloon-date-month {
  font-size: .7em;
  position: relative;
  top: -10px;
}
#timeline .timeline-badge.timeline-filter-movement {
  background-color: #ffffff;
  font-size: 1.7em;
  height: 35px;
  margin-left: -18px;
  width: 35px;
  top: 40px;
}
#timeline .timeline-badge.timeline-filter-movement a span {
  color: #4997cd;
  font-size: 1.3em;
  top: -1px;
}
#timeline .timeline-badge.timeline-future-movement {
  background-color: #ffffff;
  height: 35px;
  width: 35px;
  font-size: 1.7em;
  top: -16px;
  margin-left: -18px;
}
#timeline .timeline-badge.timeline-future-movement a span {
  color: #4997cd;
  font-size: .9em;
  top: 2px;
  left: 1px;
}
#timeline .timeline-movement {
  border-bottom: dashed 1px #4997cd;
  position: relative;
}
#timeline .timeline-movement.timeline-movement-top {
  height: 60px;
}
#timeline .timeline-movement .timeline-item {
  padding: 20px 0;
}
#timeline .timeline-movement .timeline-item .timeline-panel {
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  background-color: #FFFFFF;
  color: #666;
  padding: 10px;
  position: relative;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}
#timeline .timeline-movement .timeline-item .timeline-panel .timeline-panel-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#timeline .timeline-movement .timeline-item .timeline-panel.credits .timeline-panel-ul {
  text-align: right;
}
#timeline .timeline-movement .timeline-item .timeline-panel.credits .timeline-panel-ul li {
  color: #666;
}
#timeline .timeline-movement .timeline-item .timeline-panel.credits .timeline-panel-ul li span.importo {
  color: #468c1f;
  font-size: 1.3em;
}
#timeline .timeline-movement .timeline-item .timeline-panel.debits .timeline-panel-ul {
  text-align: left;
}
#timeline .timeline-movement .timeline-item .timeline-panel.debits .timeline-panel-ul span.importo {
  color: #e2001a;
  font-size: 1.3em;
}

.info_cel {
  color: black;
  font-weight: 100;
}

.infoTitleWorking {
  font-size: 16px;
  color: #3B0B0B;
}

.infoNameClient {
  color: #0174DF;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  font-weight: 600;
  word-wrap: break-word;
  text-transform: uppercase;
}

.infoAddress {
  color: black;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}

.carousel {
  height: 243px;
}

.infoBox > img {
  position: absolute !important;
  top: -1px;
  right: 0;
  z-index: 600;
}

.infoBoxContainer {
  background-color: white;
  border: 0px solid #484848 !important;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3) !important;
  margin-bottom: 8px;
  position: relative !important;
  width: 325px;
}

.infoBoxContainer::before {
  border: 8px solid transparent;
  border-bottom: 0;
  border-top-color: #484848;
  bottom: -8px;
  content: " ";
  display: inline-block;
  left: 50%;
  margin-left: -8px;
  position: absolute;
}

.infoBoxContainer::after {
  border: 8px solid transparent;
  border-bottom: 0px;
  border-top-color: white;
  bottom: -8px;
  content: " ";
  display: inline-block;
  left: 50%;
  margin-left: -8px;
  position: absolute;
}

.infoBoxContent,
.infoBoxFooter {
  padding: 8px;
}

h2.infoBoxContentTitle {
  word-wrap: break-word;
  font-size: 19px;
  line-height: 22px;
  font-weight: 700;
}

h3.infoBoxContentLocation {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  font-weight: 700;
  word-wrap: break-word;
  text-transform: uppercase;
}

.infoBoxContent p {
  word-wrap: break-word;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  font-weight: 300;
}

.infoBoxFooter {
  text-align: center;
}

.infoBoxContainer .carousel.carousel-slider {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.divContentIcons {
  display: inline-block;
  padding: 8px 14px; /* Vertical, horizontal */
}

.divContentIcons img {
  margin-left: auto;
  margin-right: auto;
}

.infoTitleIcon {
  font-size: 10px;
}

.btnIcon {
  background-color: #FFFFFF;
  position: absolute;
  top: 30%;
  left: 3.2%;
  -webkit-transform: translate(-20%, -20%);
  transform: translate(-20%, -20%); /* horizontal y Vertical*/
}

.btn-highlight-1, .btn-highlight-1 {
  color: #fff;
  background-color:#ac2e2d;
  outline: none;
}

.buttonText {
  color: white;
}

.tittleInfo {
  font-size: 22px;
  color: white;
}

.liInfo {
  padding-bottom : 20px !important;
  font-size: 19px;
}

.list-unordered-alt {
  list-style: none;
  padding: 0px;
}

.type-neutral-11 {
  color: #fff !important;
}

.backgroundFacebook {
  background: #ffffff;
}

.fa-facebook {
  color: #4267b2;
}

.followFacebook {
  border:none;
  overflow:hidden
}

.titleFacebook {
  font-size: 50px;
  color: #4267b2;
}

.contentFacebook {
  font-size: 24px;
}

.buttonFollow {
  border: 0;
  overflow: hidden;
  width: 213px;
}

.backgroundTwitter {
  background: #171e27;
}

.titleTwitter {
  font-size: 50px;
  color: #ffffff;
}

.contentTwitter {
  font-size: 20px;
  color: #ffffff;
}

.background_mission {
  background: #424242;
}

.contentPrincipal {
  color: #FFFFFF;
  font-size: 16px;
}

.spaceImage {
  padding-bottom: 1cm;
}

.title_prin_mission {
  color: #FFFFFF;
  font-size: 30px;
}

.space_title_mission {
  padding-bottom: 1cm;
}

.backgroundClientsPlace {
  background:	#0079bf;
  color: #fff;
}

.tittle_ {
  font-size: 22px;
  color: #fff;
}


.section-love-header {
  	padding-bottom : 2em;
}


.layout-company-logos img {
 display:inline-block;
 margin:0 2.5em 1em;
 height:30px
}


.layout-company-logos img {
  height:60px
}

.titleCustomerMaps {
  color: #FFFFFF;
}

.slick-prev {
  z-index: 100;
  left: 20px !important;

}
.slick-next {
  z-index: 100;
  right: 20px !important;
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-bottom: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
    position: fixed;
    z-index: 999;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.loaderPacman {
  width: 120px;
  height: 120px;
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.backgroundSubscribeNotification {
  background: #ffffff;
}

.btn-noti-activate {
  background-color: #3498db;
  color: #ffffff;
}

.btn-noti-disabled {
  color: #ffffff;
}

/*=============================================
SLIDE
=============================================*/

.slide{
	position:relative;
	margin:auto;
	width:100%;
	overflow: hidden;
	padding:0;
}


.slide img{
	width:100%;
}

.slide .slideOpcion1 img{
	position:absolute;
	opacity:1;
	/*top:15%;
	right:10%;
	width:45%;*/
}

.slide .slideOpcion1 .textosSlide{
	position:absolute;
	/*width:40%;
	top:20%;
	left:10%;*/
}


.slide .slideOpcion2 img{
	position:absolute;
	opacity:1;
	/*top:5%;
	left:15%;
	width:25%;*/
}

.slide .slideOpcion2 .textosSlide{
	position:absolute;
	text-align:right;
	/*width:40%;
	top:15%;
	right:15%;*/
}

.slide .slideOpciones h1{	
	text-transform: uppercase;
	font-family: 'Ubuntu Condensed', sans-serif;
	opacity:1;
	top:-10%;
	/*color:#333;*/
}

.slide .slideOpciones h2{
	text-transform: uppercase;
	font-family: 'Ubuntu Condensed', sans-serif;
	opacity:1;
	top:-10%;
	/*color:#777;*/
}

.slide .slideOpciones h3{
	text-transform: uppercase;
	font-family: 'Ubuntu Condensed', sans-serif;
	opacity:1;
	top:-10%;
	/*color:#888;*/
}

.slide .slideOpciones button{
	border:0px;
	margin-top:10px;
	
	opacity:1;
	top:-10%;
}

.slide .slideOpciones button:hover{
	background:black;
	color:white;
	border:0px;
}

.slide .slideOpciones button span{
	margin-left:10px;
}

/*=============================================
FLECHAS
=============================================*/

.slide .flechas{
	position:absolute;
	font-size:2em;
	color:white;
	cursor:pointer;
	z-index:2;
}

.slide #retroceder{
	background:#666;
	left:0;
	opacity: 0;
}

.slide #avanzar{
	background:#666;
	right:0;
	opacity: 0;
}

.slide #retroceder:hover, .slide #avanzar:hover{
	background:#777;
}

.slide #retroceder:active, .slide #avanzar:active{
	background:#444;
}

/*=============================================
PAGINACIÓN
=============================================*/

.slide #paginacion{
	position: absolute;
	width:100%;
	margin:auto;
	text-align: center;
	line-height: 40px;
	font-size:12px;
	bottom:0px;
	left:0px;
	z-index:1;
	background:rgba(0,0,0,.05);
}

.slide #paginacion li{
	display:inline-block;
	margin:0px 5px;
	cursor:pointer;
	opacity:.5;
	color:white;
	text-shadow:0px 0px 5px #333;
}

.slide #paginacion li:first-child{
	opacity:1;
}


/*=============================================
ESCRITORIO GRANDE (LG revisamos en 1366px en adelante)
=============================================*/

@media (min-width:1200px){

	.slide #retroceder{
		top:37%;
		padding:40px 15px 40px 10px;
		font-size:22px;
	}

	.slide #avanzar{
		top:37%;
		padding:40px 10px 40px 15px;
		font-size:22px;
	}

	.slide .slideOpciones h1{			
		font-size:50px;	
		margin-top:5px;
	}

	.slide .slideOpciones h2{			
		font-size:35px;	
	}

	.slide .slideOpciones h3{	
		font-size:30px;	
	}

	.slide .slideOpciones button{
		padding:10px;
	}	

}

/*=============================================
ESCRITORIO MEDIANO O TABLET HORIZONTAL (MD revisamos en 1024px)
=============================================*/

@media (max-width:1199px) and (min-width:992px){

	.slide #retroceder{
		top:35%;
		padding:40px 15px 40px 10px;
		font-size:18px;
	}

	.slide #avanzar{
		top:35%;
		padding:40px 10px 40px 15px;
		font-size:18px;
	}

	.slide .slideOpciones h1{			
		font-size:35px;	
		margin-top:5px;
	}

	.slide .slideOpciones h2{			
		font-size:30px;	
	}

	.slide .slideOpciones h3{	
		font-size:20px;	
	}

	.slide .slideOpciones button{
		padding:10px;
	}	

}

/*=============================================
ESCRITORIO PEQUEÑO O TABLET VERTICAL (SM revisamos en 768px)
=============================================*/

@media (max-width:991px) and (min-width:768px){

	.slide #retroceder{
		top:35%;
		padding:30px 8px 30px 5px;
		font-size:14px;
	}

	.slide #avanzar{
		top:35%;
		padding:30px 5px 30px 8px;
		font-size:14px;
	}

	.slide .slideOpciones h1{			
		font-size:25px;	
		margin-top:5px;
	}

	.slide .slideOpciones h2{			
		font-size:20px;	
	}

	.slide .slideOpciones h3{	
		font-size:15px;	
	}

	.slide .slideOpciones button{
		padding:5px;
	}	

}

/*=============================================
MOVIL (XS revisamos en 320px)
=============================================*/

@media (max-width:767px){

	.slide #retroceder, .slide #avanzar{
		display:none;
	}

	.slide .slideOpciones h1{			
		font-size:10px;	
		margin-top:5px;
		margin-bottom:-20px;
	}

	.slide .slideOpciones h2{			
		display:none;
	}

	.slide .slideOpciones h3{	
		font-size:8px;	
		margin-bottom:-5px;
	}

	.slide .slideOpciones button{
		padding:5px;
		font-size:10px;
	}	
	
}
.backgroundCameras {
  background-color: #292A2B !important;
}

.titleCameras {
  font-size: 31px;
  line-height: 1.5;
  margin: 0 0 20px;
  color: #CAC8C6;
}

.pointsCameras {
  font-size: 17px;
  color: #CAC8C6;
}

.title_partner {
  font-size: 21px;
  line-height: 1.5;
  margin: 0 0 20px;
  color: #CAC8C6;
}

.color_diamond {
  color: #FF0000;
}

.backgroundCamerasDahua {
  background-color: #2980B9 !important;
}

.title_camera_dahua {
  color: #FDFEFE;
  font-size: 31px;
}

.title_partner_dahua {
  color: #FDFEFE;
  font-size: 21px;
}
.backgroundInfoCompany {
  background-color: #273547 !important;
}

.ptxxxl {
  padding-top: 120px !important;
}

.pbxxxl {
  padding-bottom: 120px !important;
}

.infoTitle {
  font-size: 24px;
  color: white;
}

.infoContent {
  font-size: 16px;
  color: white;
}

.backgroundYoutube {
  border-top: 5px solid #EDEDED;
}

.backgroundCompany {
  background-color: #42548e !important;
}

.textCompany {
  font-size: 23px;
  line-height: 1.5;
  margin: 0 0 20px;
  color: white;
}

.backgroundMission {
  background-color: #ffffff !important;
}

.buttonComment {
  background: rgba(196,201,204,0.3)
}

.lettersButton {
  color: white;
  font-size: 18px;
}

.styleButton {
  border: 0;
  border-radius: .3em;
  box-shadow: 0 2px 0 #a5acb0;
  color: #4d4d4d;
  cursor: pointer;
  display: inline-block;
  font-size: .9em;
  font-weight: bold;
  line-height: 1.2;
  margin: .3em 1em 1em 0;
  outline: 0;
  padding: .7em 1.2em;
  position: relative;
  text-decoration: none;
}

.banner-font-image {
  font-size: 10em;
}

.fa-youtube {
  color: red;
}

.descriptionYoutube {
  font-size: 18px;
}

.buttonYoutube {
  overflow: hidden; height: 24px; width: 170px; border: 0;
}

.divOcult {
 display: none;
}

.divVisible {
  display: block;
}

.titleYoutube {
  color: '#ffffff';
}

.pEmail {
  text-align: center;
}

.mail-box {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
}
.mail-box aside {
    display: table-cell;
    float: none;
    height: 100%;
    padding: 0;
    vertical-align: top;
}
.mail-box .sm-side {
    background: none repeat scroll 0 0 #e5e8ef;
    border-radius: 4px 0 0 4px;
    width: 25%;
}
.mail-box .lg-side {
    background: none repeat scroll 0 0 #fff;
    border-radius: 0 4px 4px 0;
    width: 75%;
}
.mail-box .sm-side .user-head {
    background: none repeat scroll 0 0 #585858;
    border-radius: 4px 0 0;
    color: #fff;
    min-height: 80px;
    padding: 10px;
}
.user-head .inbox-avatar {
    float: left;
    width: 65px;
}
.user-head .inbox-avatar img {
    border-radius: 4px;
}
.user-head .user-name {
    display: inline-block;
    margin: 0 0 0 10px;
}
.user-head .user-name h5 {
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 15px;
}
.user-head .user-name h5 a {
    color: #fff;
}
.user-head .user-name span a {
    color: #87e2e7;
    font-size: 12px;
}
a.mail-dropdown {
    background: none repeat scroll 0 0 #80d3d9;
    border-radius: 2px;
    color: #01a7b3;
    font-size: 10px;
    margin-top: 20px;
    padding: 3px 5px;
}
.inbox-body {
    padding: 20px;
}
.btn-compose {
    background: none repeat scroll 0 0 #ff6c60;
    color: #fff;
    padding: 12px 0;
    text-align: center;
    width: 100%;
}
.btn-compose:hover {
    background: none repeat scroll 0 0 #f5675c;
    color: #fff;
}
ul.inbox-nav {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 100%;
}
.inbox-divider {
    border-bottom: 1px solid #d5d8df;
}
ul.inbox-nav li {
    display: inline-block;
    line-height: 45px;
    width: 100%;
}
ul.inbox-nav li a {
    color: #6a6a6a;
    display: inline-block;
    line-height: 45px;
    padding: 0 20px;
    width: 100%;
}
ul.inbox-nav li a:hover, ul.inbox-nav li.active a, ul.inbox-nav li a:focus {
    background: none repeat scroll 0 0 #d5d7de;
    color: #6a6a6a;
}
ul.inbox-nav li a i {
    color: #6a6a6a;
    font-size: 16px;
    padding-right: 10px;
}
ul.inbox-nav li a span.label {
    margin-top: 13px;
}
ul.labels-info li h4 {
    color: #5c5c5e;
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    text-transform: uppercase;
}
ul.labels-info li {
    margin: 0;
}
ul.labels-info li a {
    border-radius: 0;
    color: #6a6a6a;
}
ul.labels-info li a:hover, ul.labels-info li a:focus {
    background: none repeat scroll 0 0 #d5d7de;
    color: #6a6a6a;
}
ul.labels-info li a i {
    padding-right: 10px;
}
.nav.nav-pills.nav-stacked.labels-info p {
    color: #9d9f9e;
    font-size: 11px;
    margin-bottom: 0;
    padding: 0 22px;
}
.inbox-head {
    background: none repeat scroll 0 0 #41cac0;
    border-radius: 0 4px 0 0;
    color: #fff;
    min-height: 80px;
    padding: 20px;
}
.inbox-head h3 {
    display: inline-block;
    font-weight: 300;
    margin: 0;
    padding-top: 6px;
}
.inbox-head .sr-input {
    border: medium none;
    border-radius: 4px 0 0 4px;
    box-shadow: none;
    color: #8a8a8a;
    float: left;
    height: 40px;
    padding: 0 10px;
}
.inbox-head .sr-btn {
    background: none repeat scroll 0 0 #00a6b2;
    border: medium none;
    border-radius: 0 4px 4px 0;
    color: #fff;
    height: 40px;
    padding: 0 20px;
}
.table-inbox {
    border: 1px solid #d3d3d3;
    margin-bottom: 0;
}
.table-inbox tr td {
    padding: 12px !important;
}
.table-inbox tr td:hover {
    cursor: pointer;
}
.table-inbox tr td .fa-star.inbox-started, .table-inbox tr td .fa-star:hover {
    color: #f78a09;
}
.table-inbox tr td .fa-star {
    color: #d5d5d5;
}
.table-inbox tr.unread td {
    background: none repeat scroll 0 0 #f7f7f7;
    font-weight: 600;
}
ul.inbox-pagination {
    float: right;
}
ul.inbox-pagination li {
    float: left;
}
.mail-option {
    display: inline-block;
    margin-bottom: 10px;
    width: 100%;
}
.mail-option .chk-all, .mail-option .btn-group {
    margin-right: 5px;
}
.mail-option .chk-all, .mail-option .btn-group a.btn {
    background: none repeat scroll 0 0 #fcfcfc;
    border: 1px solid #e7e7e7;
    border-radius: 3px !important;
    color: #afafaf;
    display: inline-block;
    padding: 5px 10px;
}
.inbox-pagination a.np-btn {
    background: none repeat scroll 0 0 #fcfcfc;
    border: 1px solid #e7e7e7;
    border-radius: 3px !important;
    color: #afafaf;
    display: inline-block;
    padding: 5px 15px;
}
.mail-option .chk-all input[type="checkbox"] {
    margin-top: 0;
}
.mail-option .btn-group a.all {
    border: medium none;
    padding: 0;
}
.inbox-pagination a.np-btn {
    margin-left: 5px;
}
.inbox-pagination li span {
    display: inline-block;
    margin-right: 5px;
    margin-top: 7px;
}
.fileinput-button {
    background: none repeat scroll 0 0 #eeeeee;
    border: 1px solid #e6e6e6;
}
.inbox-body .modal .modal-body input, .inbox-body .modal .modal-body textarea {
    border: 1px solid #e6e6e6;
    box-shadow: none;
}
.btn-send, .btn-send:hover {
    background: none repeat scroll 0 0 #00a8b3;
    color: #fff;
}
.btn-send:hover {
    background: none repeat scroll 0 0 #009da7;
}
.modal-header h4.modal-title {
    font-family: "Open Sans",sans-serif;
    font-weight: 300;
}
.modal-body label {
    font-family: "Open Sans",sans-serif;
    font-weight: 400;
}
.heading-inbox h4 {
    border-bottom: 1px solid #ddd;
    color: #444;
    font-size: 18px;
    margin-top: 20px;
    padding-bottom: 10px;
}
.sender-info {
    margin-bottom: 20px;
}
.sender-info img {
    height: 30px;
    width: 30px;
}
.sender-dropdown {
    background: none repeat scroll 0 0 #eaeaea;
    color: #777;
    font-size: 10px;
    padding: 0 3px;
}
.view-mail a {
    color: #ff6c60;
}
.attachment-mail {
    margin-top: 30px;
}
.attachment-mail ul {
    display: inline-block;
    margin-bottom: 30px;
    width: 100%;
}
.attachment-mail ul li {
    float: left;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 150px;
}
.attachment-mail ul li img {
    width: 100%;
}
.attachment-mail ul li span {
    float: right;
}
.attachment-mail .file-name {
    float: left;
}
.attachment-mail .links {
    display: inline-block;
    width: 100%;
}

.fileinput-button {
    float: left;
    margin-right: 4px;
    overflow: hidden;
    position: relative;
}
.fileinput-button input {
    cursor: pointer;
    direction: ltr;
    font-size: 23px;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: translate(-300px, 0px) scale(4);
            transform: translate(-300px, 0px) scale(4);
}
.fileupload-buttonbar .btn, .fileupload-buttonbar .toggle {
    margin-bottom: 5px;
}
.files .progress {
    width: 200px;
}
.fileupload-processing .fileupload-loading {
    display: block;
}
* html .fileinput-button {
    line-height: 24px;
    margin: 1px -3px 0 0;
}
* + html .fileinput-button {
    margin: 1px 0 0;
    padding: 2px 15px;
}
@media (max-width: 767px) {
.files .btn span {
    display: none;
}
.files .preview * {
    width: 40px;
}
.files .name * {
    display: inline-block;
    width: 80px;
    word-wrap: break-word;
}
.files .progress {
    width: 20px;
}
.files .delete {
    width: 60px;
}
}
ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.spaceTopElement {
  padding-top: 10px;
}

.spaceElements {
  padding: 8px;
}

.backgroundDiva {
  background: none repeat scroll 0 0 #424242;
}

.tittleListVideos {
  color: #ffffff;
}

a, a:link, a:hover{
	text-decoration: none;
}
.search-ip{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.form-search {
    border-radius: 0;
    max-width: 600px;
    min-width: 450px;
}
.btn-search{
	border-radius: 0;
    padding-right: 40px;
    padding-left: 40px;
}
.img-logo {
    height: 50px;
    margin-top: -16px;
}
.mn-top{
    background-color: #fff;
    padding-top: 63px;
    padding-bottom: 3px;
    text-align: center;
    font-size: 1.1em;
}
.mn-top span{
    color: #666;
    margin-left: 10px;
    padding-bottom: 13px;
}
.mn-top span:hover{
    border-bottom: 3px solid #cc181e;
}
.sidebar.left {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    bottom: 0;
    width: 220px;
    background: #ffffff;
    box-shadow: 1px 0px 11px 0px rgba(50, 50, 50, 0.2);
}
.m-t-60 {
    margin-top: 63px;
}
.p-10 {
    padding: 10px;
}
.actv-side {
    background-color: #cc181e;
    color: #fff;
}
.id-ctgr {
    padding: 7px 5px;
    margin-bottom: 3px;
}
.id-ctgr:hover {
    background-color: #222;

}
.id-ctgr:hover i{
    margin-right: 8px;
}
.item-sub{
	background-color: #fff;
    color: #000;
}
.item-sub:hover{
    color: #fff;
}
.style10 {
    padding: 0px 0;
}
.my-sideber{
    height: 890px;
    overflow-y: auto;
}
@media (min-width: 1200px){
.m-left {
    margin-left: 232px;
}
}
.tool-ft{
   cursor: pointer;
   position: fixed;
   bottom: 20px;
   right: 20px;
   display:block;
}
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}
/* VIDEO API CSS */
.video-list-thumbs{}
.video-list-thumbs > li{
    margin-bottom:12px
}
.video-list-thumbs > li:last-child{}
.video-list-thumbs > li > span{
  display:block;
  position:relative;
  background-color: #212121;
  color: #fff;
  padding: 0px;
}
.video-list-thumbs > li > span:hover{
  background-color:#000;
  -webkit-transition:all 500ms ease;
  transition:all 500ms ease;
  box-shadow:0 2px 4px rgba(0,0,0,.3);
  text-decoration:none
}


.video-list-thumbs li h2 {
    font-size: 13px;
		color: #ffffff;
    padding-bottom: 4px !important;
    text-align: center;
    margin: 5px 3px 3px 0;
    line-height:18px;
    overflow:hidden;
    display:-webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-flex:1;
}
.video-list-thumbs .fa-play-circle-o{
    font-size: 60px;
    opacity: 0.2;
    position: absolute;
    right: 39%;
    top: 31%;
    text-shadow: 0 1px 3px rgba(0,0,0,.5);
}
.video-list-thumbs > li > a:hover .fa-play-circle-o{
  color:#fff;
  opacity:1;
  text-shadow:0 1px 3px rgba(0,0,0,.8);
  -webkit-transition:all 500ms ease;
  transition:all 500ms ease;
}

.video-list-thumbs .time{
    background-color: rgba(0, 200, 240, 0.8);
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  right: 0;
  line-height: 13px;
  padding: 4px;
  position: absolute;
  bottom: 44px;
}

.video-list-thumbs .duration{
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  left: 8px;
  line-height: 13px;
  padding: 4px;
  position: absolute;
  top: 8px;
}
.video-list-thumbs > li > a:hover .duration{
  background: rgba(184, 44, 44, 0.9);
  -webkit-transition:all 500ms ease;
  transition:all 500ms ease;
}
@media (min-width:320px) and (max-width: 480px) {
  .video-list-thumbs .fa-play-circle-o{
    font-size: 35px;
    right: 36%;
    top: 27%;
  }
  .video-list-thumbs h2{
    bottom: 0;
        padding: 3px;
    font-size: 12px;
    height: 22px;
    margin: 8px 0 0;
  }
}
/* END */
@media (max-width: 1200px){
   .sidebar{
      display: none;
   }
}
.active-mn-top{
   border-bottom: 3px solid #cc181e;
   color: #333 !important;
   font-weight: 600;
}
.ads-bg-top{
   margin-bottom: 28px;
}
.title-topic span{
    font-size: 18px;
    -moz-text-transform: uppercase;
    -webkit-text-transform: uppercase;
    text-transform: uppercase;
    color: white;
}
.title-topic{
   margin-bottom: 7px;
}
.pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
        user-select: none
}
.pace-inactive {
    display: none
}
.pace .pace-progress {
    background: #cc181e;
    position: fixed;
    z-index: 100000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 2px
}

.styleViews {
	color: #ffffff;
	text-align: center;
	font-size: 12px;
}

.styleDescription {
	color: #ffffff;
	font-size: 11px;
}

.tamVideo {
	height: 190px;
}

/* top section */
.top-section {
    padding: 10px 0 0;
    background: #000;
    color: #FFFFFF;
    font-size: 13px;
}
.top-section ul li a {
    color: #FFFFFF;
    font-size: 14px;
}
/* top section Ends here */

/* header section starts here */
.header-section {
    padding: 25px 0;

}
.search-form .btn {
    padding: 14.5px;
    border-radius: 0;
}
.navbar {
    margin-bottom:0px;
}
.navbar-brand {
    float: left;
    padding: 0;
}
.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    color: #fff;
    text-decoration: none;

}
.navbar-brand img {
    float: left;
    -webkit-transition: all .5s ease-out;
    transition: all .5s ease-out;
    margin: 0;
}
.header input {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 1px 2px 0 rgba(0, 0, 0, .24);
}
.form-control, select {
    display: block;
    width: 100%;
    height: 44px;
    border: 1px solid #ededed;
    border-radius: 0;
    background-color: #fff;
    background-image: none;
    box-shadow: none;
    color: #000;
    font-size: 14px;
    line-height: 1.42857143;
}

.margin_60 {
    padding-top: 60px;
    padding-bottom: 60px;
}
.main_title {
    text-align: center;
    font-size: 16px;
    margin-bottom: 30px;
}
.main_title h2 {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: -1px;
    font-size: 30px;
    margin-bottom: 0;
    margin-top: 0;
}

.main_title p {
    font-weight: 300;
    font-size: 20px;
}


.crane_container {
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
    margin: 0 0 30px;
}
.ribbon_3 {
    position: absolute;
    left: 10px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}
.ribbon_3.popular span {
    background: -webkit-linear-gradient(#f14f3f 0,#cb4335 100%);
    background: linear-gradient(#f14f3f 0,#cb4335 100%);
}
.ribbon_3 span {
    font-size: 10px;
    font-weight: 700;
    color: #FFF;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: #79A70A;
    background: -webkit-linear-gradient(#9BC90D 0,#79A70A 100%);
    background: linear-gradient(#9BC90D 0,#79A70A 100%);
    box-shadow: 0 3px 10px -5px rgba(0,0,0,1);
    text-shadow: 1px 1px 2px rgba(0,0,0,.25);
    position: absolute;
    top: 19px;
    left: -21px;
}
.ribbon_3 span {
    text-transform: uppercase;
}
.ribbon_3.popular span::before {
    border-left: 3px solid #a3362a;
    border-right: 3px solid transparent;
}
.ribbon_3.popular span::after {
    border-left: 3px solid transparent;
    border-right: 3px solid #a3362a;
}

.carbox {
  display: block;
    margin-bottom: 20px;
    line-height: 1.42857143;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    -webkit-transition: box-shadow .25s;
    transition: box-shadow .25s;
}
.carbox:hover {
  box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}
.img-carbox {
  width: 100%;
  height:200px;
  border-top-left-radius:2px;
  border-top-right-radius:2px;
  display:block;
    overflow: hidden;
}
.img-carbox img{
  width: 100%;
  height: 200px;
  object-fit:cover;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
}
.carbox-content {
  padding:15px;
  text-align:left;
}
.carbox-title {
  margin-top:0px;
  font-weight: 700;
  font-size: 1.23em;
}
.carbox-title a {
  color: #000;
  text-decoration: none !important;
}
.carbox-read-more {
  border-top: 1px solid #D4D4D4;
}
.carbox-read-more a {
  text-decoration: none !important;
  padding:10px;
  font-weight:600;
  text-transform: uppercase
}
/* Button Orange Css */
.margin-b-30 {
    margin-bottom: 30px;
}
.btn_orange.medium, a.btn_orange.medium {
    padding: 10px 25px;
    font-size: 14px;
}

.btn_orange, a.btn_orange {
    border: none;
    font-family: inherit;
    color: #fff;
    background: #51bce6;
    cursor: pointer;
    padding: 7px 20px;
    display: inline-block;
    outline: 0;
    font-size: 12px;
    -webkit-transition: all .3s;
    transition: all .3s;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: 700;
}


.white_bg {
    background-color: #fff;
}


.more_cranes ul li a {
    border-bottom: 1px solid #ededed;
    padding: 5px 0 10px;
    display: block;
    color: #333;
}
.add_bottom_45 {
    margin-bottom: 45px;
}
.more_cranes ul {
    padding: 0px;
    margin: 0;
}

.more_cranes ul li a i {
    font-size: 22px;
    margin-right: 5px;
    margin-left: 2px;
    top: 5px;
    color: #555;

}
.more_cranes ul {
    list-style: none;

}
.more_cranes_price {
    float: right;
    margin-top: 5px;
    margin-right: 5px;
    color: #888;
}

.icon {
    padding:0px;
    font-size:40px;
    border-radius: 100px;
    font-size: 28px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    width: 70px;
}

.reason_box {
    padding: 30px;
    background: #fff;
    margin-bottom: 30px;
    color: #888;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
}

.conversion-section {
    background-color: #FFFFFF;
    margin-top: 25px;
    padding: 25px 30px;
}
.conversion h3 {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
}
.conversion a.btn {
    margin-top: 24px;
}
.btn-danger, .dropdown-toggle.btn-danger {
    background-color: #ff5722;
}
.btn-danger:hover {
    background-color: #ff5722;
}

.places {
    overflow: hidden;
    margin: 0;
    padding: 0;
    list-style: none;
}
.places li {
    float: left;
}
.place {
    position: relative;
    display: inline-block;
    margin: 0 10px 10px 0;
    padding: 0 20px 0 23px;
    height: 26px;
    border-radius: 3px 0 0 3px;
    background: #F3F3F3;
    color: #000;
    text-decoration: none;
    font-size: 14px;
    line-height: 26px;
    -webkit-transition: all .4s;
}
.place::after {
    position: absolute;
    top: 0;
    right: 0;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-left: 10px solid #EFF1F5;
    background: #fff;
    content: '';
    -webkit-transition: all .4s;
}
.carbox1 {
    padding-left:5px;
    display: block;
    line-height: 1.42857143;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    -webkit-transition: box-shadow .25s;
    transition: box-shadow .25s;
}
.carbox1:hover {
  box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}

.navcarbox {
    padding-left:5px;
    display: block;
    line-height: 1.42857143;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    -webkit-transition: box-shadow .25s;
    transition: box-shadow .25s;
}

.carousel-control {
    color: brown;
}

.carousel-indicators .active {
    background-color: #ff5900;

}

.carousel-inner img {
    width: 100%;
    max-height: 420px;
}

.carousel-control {
    width: 0;
}

.carousel-control.left,
.carousel-control.right {
	opacity: 1;
	filter: alpha(opacity=100);
	background-image: none;
	background-repeat: no-repeat;
	text-shadow: none;
	margin-right: 95px;
}

.carousel-control.left span {
	margin-left: 15px;
	font-size: 60px;


}

.carousel-control.right span {
	font-size: 60px;
    margin-right: 50px;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-prev,
.carousel-control .icon-next {
	position: absolute;
	top: 45%;
	z-index: 5;
	display: inline-block;

}

.carousel-control .glyphicon-chevron-left,
.carousel-control .icon-prev {
	left: 0;
}

.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next {
	right: 0;

}

.carousel-control.left span,
.carousel-control.right span {
	background-color: none;

}

.carousel-control.left span:hover,
.carousel-control.right span:hover {
	opacity: .7;
	filter: alpha(opacity=70);
}

/* Carousel Header Styles */
.header-text {
    position: absolute;
    top: 35%;
    left: 1.8%;
    right: auto;
    width: 96.66666666666666%;
    color: #000;
}

.header-text h2 {
    font-size: 30px;
}

.header-text h2 span {

	padding: 10px;
}

.header-text h3 span {

	padding: 15px;
}
.btn-min-block {
    min-width: 170px;
    line-height: 26px;
}

.btn-theme {
    color: #fff;
    background-color: transparent;
    border: 2px solid #fff;
    margin-right: 15px;
}

.btn-theme:hover {
    color: #000;
    border-color: #fff;
}


/* listing css */

.tb-padding{padding:50px 0px;}

.cover-size{width:100%;}

ul{list-style:none; padding:0px; margin:0px;}

.side-bar{border:1px solid #c2c2c2; margin-top:20px;}

.side-bar ul li{padding:10px 0px; border-bottom:1px solid #c2c2c2;}

.side-bar ul li:last-child{border:none;}

.side-bar ul li i{color:#c2c2c2; padding:0px 5px;}



.side-bar ul li a{text-decoration:none;}



.need-help{padding:5px; border:1px solid #c2c2c2; margin-top:10px;}

.need-help i{font-size:45px; color:#ff0000; padding:20px 0px;}

.search-box{background-color:#dedede;padding:5px 0px;}

.search-box select{font-size:12px; height:23px;}

.search-box i{float:right; padding:5px; background-color:#fff; margin-right:5px;}

.image-size > img{width:100%;}

p span{color:#c2c2c2;  padding:0px 5px;}

.customs-p p{line-height:20px; margin:0px;}

.small-padding-bg{padding:20px 0px;  margin:10px 0px;}

.small-padding-bg:hover{box-shadow:3px 3px 5px #000000, -3px -3px 5px #000000;}

.customs-margin{margin-top:30px;}


.youtube_ {
  color: #ffffff;
  font-size: 5em;
  vertical-align: middle;
}

.tittleChannel {
  font-size: 30px;
  color: #FFFFFF;
  padding: 22px;
}

.backgroundDiv {
  background-color: #2E2E2E;
}

.starVideos {
  font-size: 22px;
}

.backgroundDivPrincipal {
  color: #BDBDBD;
}

.testBackground {
  background-image: url(https://res.cloudinary.com/dlaew2qjk/image/upload/v1520885887/assets/static/tutorials/fondo_3_cjawvt.jpg);
}

.otherTest {
  padding: 2px;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.averageSize {
  font-size: 70px;
}

.totalSize {
  font-size: 20px;
}

.backgroundStar {
  font-size: 18px;
  background-color: #86B404;
}

.starItem {
  font-size: 20px;
  color: #000000
}

.colorStar_ {
  font-size:24px;
  color:#ffd700;
}

.letterCapitealize {
  text-transform: capitalize;
}

.divFooter {
  height: 230px;
}

.colorOpinion {
  text-transform: lowercase;
  color: #8C8985;
}

.colorCompany {
  color: #365899;
  text-transform: uppercase;
}

.colorStar {
  font-size: 10px;
  color: #ffffff;
}

.oval {
  width: 250px;
  height: 100px;
  border-radius: 50%;
  border-right: 10px;
  background: #5890FF;
  padding: 3.5px;
}

.panel-google-plus {
    position: relative;
    border-radius: 0px;
    border: 1px solid rgb(216, 216, 216);
    font-family: 'Roboto', sans-serif;
}
.panel-google-plus > .dropdown {
    position: absolute;
    top: 5px;
    right: 15px;
}
.panel-google-plus > .dropdown > span > span {
    font-size: 10px;
}
.panel-google-plus > .dropdown > .dropdown-menu {
    left: auto;
    left: initial;
    right: 0px;
    border-radius: 2px;
}
.panel-google-plus > .panel-google-plus-tags {
    position: absolute;
    top: 35px;
    right: -3px;
}
.panel-google-plus > .panel-google-plus-tags > ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}
.panel-google-plus > .panel-google-plus-tags > ul:hover {
    box-shadow: 0px 0px 3px rgb(0, 0, 0);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
}
.panel-google-plus > .panel-google-plus-tags > ul > li {
    display: block;
    right: 0px;
    width: 0px;
    padding: 5px 0px 5px 0px;
    background-color: rgb(245, 245, 245);
    font-size: 12px;
    overflow: hidden;
}
.panel-google-plus > .panel-google-plus-tags > ul > li::after {
    content:"";
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
	border-right: 3px solid rgb(66, 127, 237);
}
.panel-google-plus > .panel-google-plus-tags > ul:hover > li,
.panel-google-plus > .panel-google-plus-tags > ul > li:first-child {
    padding: 5px 15px 5px 10px;
    width: auto;
    cursor: pointer;
    margin-left: auto;
}
.panel-google-plus > .panel-google-plus-tags > ul:hover > li {
    background-color: rgb(255, 255, 255);
}
.panel-google-plus > .panel-google-plus-tags > ul > li:hover {
    background-color: rgb(66, 127, 237);
    color: rgb(255, 255, 255);
}
.panel-google-plus > .panel-heading,
.panel-google-plus > .panel-footer {
    background-color: rgb(255, 255, 255);
    border-width: 0px;
}
.panel-google-plus > .panel-heading {
    margin-top: 20px;
    padding-bottom: 5px;
}
.panel-google-plus > .panel-heading > img {
    margin-right: 15px;
}
.panel-google-plus > .panel-heading > h3 {
    margin: 0px;
    font-size: 12px;
    font-weight: 700;
}
.panel-google-plus > .panel-heading > h5 {
  /*  color: rgb(153, 153, 153);*/
    font-size: 12px;
    font-weight: 400;
}
.panel-google-plus > .panel-body {
    padding-top: 5px;
    font-size: 13px;
    background-color:#FFF;
}
.panel-google-plus > .panel-body > .panel-google-plus-image {
    display: block;
    text-align: center;
    /*background-color: rgb(245, 245, 245);*/
    /*border: 1px solid rgb(217, 217, 217);*/
}
.panel-google-plus > .panel-body > .panel-google-plus-image > img {
    max-width: 100%;
}

.panel-google-plus > .panel-footer {
    font-size: 14px;
    font-weight: 700;
    min-height: 54px;
}
.panel-google-plus > .panel-footer > .btn {
    float: left;
    margin-right: 8px;
}
.panel-google-plus > .panel-footer > .input-placeholder {
    display: block;
    margin-left: 98px;
    /*/color: rgb(153, 153, 153);*/
    font-size: 12px;
    font-weight: 400;
    padding: 8px 6px 7px;
    border: 1px solid rgb(217, 217, 217);
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.0470588) 0px 1px 0px 0px;
}
.panel-google-plus.panel-google-plus-show-comment > .panel-footer > .input-placeholder {
    display: none;
}
.panel-google-plus > .panel-google-plus-comment {
    display: none;
    padding: 10px 20px 15px;
    border-top: 1px solid rgb(229, 229, 229);
    background-color: rgb(245, 245, 245);
}
.panel-google-plus.panel-google-plus-show-comment > .panel-google-plus-comment {
    display: block;
}

.panel-google-plus > .panel-google-plus-comment > .panel-google-plus-textarea {
    float: right;
    width: calc(100% - 56px);
}
.panel-google-plus > .panel-google-plus-comment > .panel-google-plus-textarea > textarea {
    display: block;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(217, 217, 217);
    box-shadow: rgba(0, 0, 0, 0.0470588) 0px 1px 0px 0px;
    resize: vertical;
}
.panel-google-plus > .panel-google-plus-comment > .panel-google-plus-textarea > .btn {
    margin-top: 10px;
    margin-right: 8px;
    width: 100%;
}
@media (min-width: 992px) {
    .panel-google-plus > .panel-google-plus-comment > .panel-google-plus-textarea > .btn {
        width: auto;
    }
}


.panel-google-plus .btn {
    border-radius: 3px;
}
.panel-google-plus .btn-default {
    border: 1px solid rgb(217, 217, 217);
    box-shadow: rgba(0, 0, 0, 0.0470588) 0px 1px 0px 0px;
}
.panel-google-plus .btn-default:hover,
.panel-google-plus .btn-default:focus,
.panel-google-plus .btn-default:active {
    background-color: rgb(255, 255, 255);
    border-color: rgb(0, 0, 0);
}

.colorPartner {
  color: #000000;
}

.colorActions {
  color: #6E6E6E;
  font-size: 15px;
}

/* Sección de iconos y comentarios */
/*-- Global Selectors --*/
.aPartner{
    color:#47649F;
}

/*-- Content Style --*/
.post-footer-option li{
    float:left;
    margin-right:50px;
    padding-bottom:15px;
}

.post-footer-option li a{
    color:#6E6E6E;
    font-weight:500;
    font-size:1.3rem;
}

.photo-profile{
    border:1px solid #DDD;
}

.anchor-username h4{
    font-weight:bold;
}

.anchor-time{
    color:#ADB2BB;
    font-size:1.2rem;
}

.post-footer-comment-wrapper{
    background-color:#F6F7F8;
}


.hrLine {
    font-size: 14pt;
    border-bottom: 2px solid #e5ebef;
    margin-bottom: 15px;
    padding-bottom: 10px;
    margin-top: 0px;
}

.hrLine:after {
    border-bottom: 1px solid #A4A4A4;
    width: 250px;
    display: block;
    position: absolute;
    content: '';
    padding-bottom: 10px;
}

.elementLeft {
  margin-left: 20px;
  float: left;
  text-align:left;
}

.elementRight {
  margin-right: 20px;
  float: right;
  text-align:right;
}

.colorComments {
  color: #000000;
}

.colorIconCategory {
  color:#848484;
}

.dobleImg {
  height: 570px;
  width: 100%;
}

.divSquare {
  width:49%; height:200px; margin:1px; border:.5px solid black; float: left;
}


ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 50px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}

.colorBackground {
  background-color: #007FD7;
}

.colorDateUpdate {
  color: #FFFFFF;
}

.numberColor {
  color: #0086CF;
}

  .event{
    border-radius: 4px;
    height: 150px;
    margin: 40px auto 0;
  }
  .event-side{
    padding: 10px;
    border-radius: 4px;
    float: left;
    height: 100%;
    width: calc(15% - 1px);
    box-shadow: 1px 2px 2px -1px #888;
    background: white;
    z-index: 1000;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    overflow: hidden;
    font-size: 0.8em;
    text-align: right;
  }
  .event-date, .event-time{
    position: absolute;
    width: calc(100% - 20px);
  }
  .event-date {
    top: 10px;
    font-size: 1.2em;
  }
  .event-time{
    bottom: 10px;
  }
  .to {
    display: block;
    position: relative;
    left: -25%;
  }
  .cut-out {
    border-radius: 1000px;
    width: 100%;
    height: 40%;
    display: block;
    position: absolute;
    left: -60%;
    top: 25%;
    background: #eee;
    box-shadow: inset -1px 2px 2px -1px #888
  }
  .dotted-line-separator {
    right: -2px;
    position: absolute;
    background: #fff;
    width: 5px;
    top: 8px;
    bottom: 8px;
  }
  .dotted-line-separator .line {
    /*border-right: 1px dashed #ccc;*/
    -webkit-transform:rotate(90deg);
            transform:rotate(90deg);
  }
  .dotted-line-separator .line:after {
    content: "| | | | | | | | | | | | | | | | | | | | | | | | | | |";
    overflow: hidden;
    display: block;
    position: relative;
    left: 1px;
    height: 90%;
    margin: 5px 0;
    display: block;
    color: rgba(204, 204, 204, 0.5);
  }
  .event-body {
    border-radius: 4px;
    float: left;
    height: 100%;
    width: 85%;
    box-shadow: 0 2px 2px -1px #888;
    background: white;
    padding-right: 10px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }
  .event-title, .event-location, .event-details{
    float: left;
    width: 54%;
    padding: 10px;
  }
  .event-title, .event-location {
    border-bottom: 1px solid #ccc;
  }
  .event-location, .event-details {
    height: 30%;
    line-height: 24px;
  }
  .event-title {
    height: 40%;
  }
  .event-title span{
    font-size: 0.5em;
    text-transform: uppercase;
    color: #888;
  }
  .event-title h3 {
    margin: 0;
  }
  .event-image{
    width: calc(46% - 20px);
    height: calc(100% - 20px);
    margin: 10px;
    float: left;
    overflow: hidden;
    text-align: center;
  }


.buttonFacebook {
  width: 85%;
  padding: 15px 80px;
  margin: 5px;
}

.buttonGoogle {
  width: 85%;
  padding: 15px 80px;
  margin: 5px;
}

#icon {
  width:60%;
}

.rootLogin {
}

.rootBody {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

.rootA {
  color: #92badd;
  display:inline-block;
  text-decoration: none;
  font-weight: 400;
}

.rootH2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  /*text-transform: uppercase;*/
  display:inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  border-radius: 0 0 10px 10px;
}

.rootH2.inactive {
  color: #cccccc;
}

.rootH2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

.styleLogin  {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /*text-transform: uppercase;*/
  font-size: 13px;
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.styleLogin:hover  {
  background-color: #39ace7;
}

.styleButton:active  {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

.otherStyle {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-radius: 5px 5px 5px 5px;
}

.otherStyle:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

.otherStyle:placeholder {
  color: #cccccc;
}



/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fadeIn {
  opacity:0;
  -webkit-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;

  -webkit-animation-fill-mode:forwards;
  animation-fill-mode:forwards;

  -webkit-animation-duration:1s;
  animation-duration:1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after{
  width: 100%;
}

.round-comment {
  background-color: #fff;
  width: auto;
  height: auto;
  margin: 0 auto 15px auto;
  padding: 5px;
  border: 1px solid #ccc;
  margin-left: 2em;
  border-radius: 11px;
}

.panel-shadow {
    box-shadow: rgba(0, 0, 0, 0.3) 7px 7px 7px;
}
.panel-white {
  border: 1px solid #dddddd;
}
.panel-white  .panel-heading {
  color: #333;
  background-color: #fff;
  border-color: #ddd;
}
.panel-white  .panel-footer {
  background-color: #fff;
  border-color: #ddd;
}

.post .post-heading {
  height: 95px;
  padding: 20px 15px;
}
.post .post-heading .avatar {
  width: 60px;
  height: 60px;
  display: block;
  margin-right: 15px;
}
.post .post-heading .meta .title {
  margin-bottom: 0;
}
.post .post-heading .meta .title a {
  color: black;
}
.post .post-heading .meta .title a:hover {
  color: #aaaaaa;
}
.post .post-heading .meta .time {
  margin-top: 8px;
  color: #999;
}
.post .post-image .image {
  width: 100%;
  height: auto;
}
.post .post-description {
  padding: 15px;
}
.post .post-description p {
  font-size: 14px;
}
.post .post-description .stats {
  margin-top: 20px;
}
.post .post-description .stats .stat-item {
  display: inline-block;
  margin-right: 15px;
}
.post .post-description .stats .stat-item .icon {
  margin-right: 8px;
}
.post .post-footer {
  border-top: 1px solid #ddd;
  padding: 15px;
}
.post .post-footer .input-group-addon a {
  color: #454545;
}
.post .post-footer .comments-list {
  padding: 0;
  margin-top: 20px;
  list-style-type: none;
}
.post .post-footer .comments-list .comment {
  display: block;
  width: 100%;
  margin: 20px 0;
}
.post .post-footer .comments-list .comment .avatar {
  width: 35px;
  height: 35px;
}
.post .post-footer .comments-list .comment .comment-heading {
  display: block;
  width: 100%;
}
.post .post-footer .comments-list .comment .comment-heading .user {
  font-size: 14px;
  font-weight: bold;
  display: inline;
  margin-top: 0;
  margin-right: 10px;
}
.post .post-footer .comments-list .comment .comment-heading .time {
  font-size: 12px;
  color: #aaa;
  margin-top: 0;
  display: inline;
}
.post .post-footer .comments-list .comment .comment-body {
  margin-left: 50px;
}
.post .post-footer .comments-list .comment > .comments-list {
  margin-left: 50px;
}
.sizeLetter {
  font-size: 30px;
}

.fab {
  position : absolute;
	right : 80px;
	bottom : 280px;
	box-sizing: border-box;
	padding-top: 30px;
	background-color : #16A085;
	width : 80px;
	height : 80px;
	color : white;
	text-align : center;
	border-radius : 50%;
	box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.75);
}

.sizeUser {
  font-size: 13px;
}

.media {
  margin: 8px 0;
  padding:8px;
}

.dp {
  border:10px solid #eee;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.dp:hover {
  border:2px solid #eee;
}

.colorDescription {
  color: #070719;
}

.backgroundLogo {
  background-color: #086A87;
}

.caja {
position: relative; /*Para poder ubicar la info dentro de la caja*/
margin: 10px auto;
}
.caja img {
border:2px solid black;
}
.info {
width: 300px;
position: absolute; /*Info sobre la imagen*/
top: 5%;
left: -80%; /*Desplazamos a partir de la esquina superior izquierda*/
zoom: 1;
filter: alpha(opacity=0); /*Opacidad Para IE */
opacity: 0; /*Inicialmente transparente */
padding: 5px;
color: white;
background: black; /*Aplicamos una ligera transición*/
-webkit-transition:all ease .8s ;
transition:all ease .8s;
}
.caja:hover .info {
filter: alpha(opacity=80);
opacity: .8; /*Al hacer hover sobre la caja hacemos visible los datos*/
}

.ocultText {
  display: none;
}

.Demo__some-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 30px;
  text-align: center;
}

.Demo__some-network__share-count {
  margin-top: 3px;
  font-size: 12px;
}

.Demo__some-network__share-button {
  cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.openContentJob {
   display: block;
}

.box {
	background-color:#EBF0F1;
	padding: 2.25rem 1.0rem;
}

.box + .box {
	margin-top: 2.5rem;
}

.js .inputfile {
   width: 0.1px;
   height: 0.1px;
   opacity: 0;
   overflow: hidden;
   position: absolute;
   z-index: -1;
}

.inputfile + label {
   *max-width: 80%;
   font-size: 1.25rem;
   /* 20px */
   font-weight: 700;
   text-overflow: ellipsis;
   white-space: nowrap;
   cursor: pointer;
   display: inline-block;
   overflow: hidden;
   padding: 0.625rem 1.25rem;
   /* 10px 20px */
}

.no-js .inputfile + label {
   display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
   outline: 1px dotted #000;
   outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
   /* pointer-events: none; */
   /* in case of FastClick lib use */
}

.inputfile + label svg {
   width: 1em;
   height: 1em;
   vertical-align: middle;
   fill: currentColor;
   margin-top: -0.25em;
   /* 4px */
   margin-right: 0.25em;
   /* 4px */
}
/* style 4 */

.inputfile-4 + label {
   color: #076294;
}

.inputfile-4:focus + label,
.inputfile-4.has-focus + label,
.inputfile-4 + label:hover {
   color: #076294;
}

.inputfile-4 + label figure {
   width: 100px;
   height: 100px;
   border-radius: 50%;
   background-color: #076294;
   display: block;
   padding: 20px;
   margin: 0 auto 10px;
}

.inputfile-4:focus + label figure,
.inputfile-4.has-focus + label figure,
.inputfile-4 + label:hover figure {
   background-color: #076294;
}

.inputfile-4 + label svg {
   width: 100%;
   height: 100%;
   fill: #f1e5e6;
}
