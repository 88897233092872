.round-comment {
  background-color: #fff;
  width: auto;
  height: auto;
  margin: 0 auto 15px auto;
  padding: 5px;
  border: 1px solid #ccc;
  margin-left: 2em;

  -moz-border-radius: 11px;
  -webkit-border-radius: 11px;
  border-radius: 11px;
}
