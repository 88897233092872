.averageSize {
  font-size: 70px;
}

.totalSize {
  font-size: 20px;
}

.backgroundStar {
  font-size: 18px;
  background-color: #86B404;
}

.starItem {
  font-size: 20px;
  color: #000000
}

.colorStar_ {
  font-size:24px;
  color:#ffd700;
}
