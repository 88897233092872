.background_mission {
  background: #424242;
}

.contentPrincipal {
  color: #FFFFFF;
  font-size: 16px;
}

.spaceImage {
  padding-bottom: 1cm;
}

.title_prin_mission {
  color: #FFFFFF;
  font-size: 30px;
}

.space_title_mission {
  padding-bottom: 1cm;
}
