.divContentIcons {
  display: inline-block;
  padding: 8px 14px; /* Vertical, horizontal */
}

.divContentIcons img {
  margin-left: auto;
  margin-right: auto;
}

.infoTitleIcon {
  font-size: 10px;
}

.btnIcon {
  background-color: #FFFFFF;
  position: absolute;
  top: 30%;
  left: 3.2%;
  -webkit-transform: translate(-20%, -20%);
  -moz-transform: translate(-20%, -20%);
  transform: translate(-20%, -20%); /* horizontal y Vertical*/
}
