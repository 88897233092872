.backgroundInfoCompany {
  background-color: #273547 !important;
}

.ptxxxl {
  padding-top: 120px !important;
}

.pbxxxl {
  padding-bottom: 120px !important;
}

.infoTitle {
  font-size: 24px;
  color: white;
}

.infoContent {
  font-size: 16px;
  color: white;
}

.backgroundYoutube {
  border-top: 5px solid #EDEDED;
}

.backgroundCompany {
  background-color: #42548e !important;
}

.textCompany {
  font-size: 23px;
  line-height: 1.5;
  margin: 0 0 20px;
  color: white;
}

.backgroundMission {
  background-color: #ffffff !important;
}

.buttonComment {
  background: rgba(196,201,204,0.3)
}

.lettersButton {
  color: white;
  font-size: 18px;
}

.styleButton {
  border: 0;
  border-radius: .3em;
  box-shadow: 0 2px 0 #a5acb0;
  color: #4d4d4d;
  cursor: pointer;
  display: inline-block;
  font-size: .9em;
  font-weight: bold;
  line-height: 1.2;
  margin: .3em 1em 1em 0;
  outline: 0;
  padding: .7em 1.2em;
  position: relative;
  text-decoration: none;
}
