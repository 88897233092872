.backgroundFacebook {
  background: #ffffff;
}

.fa-facebook {
  color: #4267b2;
}

.followFacebook {
  border:none;
  overflow:hidden
}

.titleFacebook {
  font-size: 50px;
  color: #4267b2;
}

.contentFacebook {
  font-size: 24px;
}
