.caja {
position: relative; /*Para poder ubicar la info dentro de la caja*/
margin: 10px auto;
}
.caja img {
border:2px solid black;
}
.info {
width: 300px;
position: absolute; /*Info sobre la imagen*/
top: 5%;
left: -80%; /*Desplazamos a partir de la esquina superior izquierda*/
zoom: 1;
filter: alpha(opacity=0); /*Opacidad Para IE */
opacity: 0; /*Inicialmente transparente */
padding: 5px;
color: white;
background: black;
-moz-transition:all ease .8s; /*Aplicamos una ligera transición*/
-webkit-transition:all ease .8s ;
transition:all ease .8s;
}
.caja:hover .info {
filter: alpha(opacity=80);
opacity: .8; /*Al hacer hover sobre la caja hacemos visible los datos*/
}

.ocultText {
  display: none;
}
