.letterCapitealize {
  text-transform: capitalize;
}

.divFooter {
  height: 230px;
}

.colorOpinion {
  text-transform: lowercase;
  color: #8C8985;
}

.colorCompany {
  color: #365899;
  text-transform: uppercase;
}

.colorStar {
  font-size: 10px;
  color: #ffffff;
}

.oval {
  width: 250px;
  height: 100px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border-right: 10px;
  background: #5890FF;
  padding: 3.5px;
}
