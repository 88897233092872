.backgroundClientsPlace {
  background:	#0079bf;
  color: #fff;
}

.tittle_ {
  font-size: 22px;
  color: #fff;
}


.section-love-header {
  	padding-bottom : 2em;
}


.layout-company-logos img {
 display:inline-block;
 margin:0 2.5em 1em;
 height:30px
}


.layout-company-logos img {
  height:60px
}

.titleCustomerMaps {
  color: #FFFFFF;
}

.slick-prev {
  z-index: 100;
  left: 20px !important;

}
.slick-next {
  z-index: 100;
  right: 20px !important;
}
