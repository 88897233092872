a, a:link, a:hover{
	text-decoration: none;
}
.search-ip{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.form-search {
    border-radius: 0;
    max-width: 600px;
    min-width: 450px;
}
.btn-search{
	border-radius: 0;
    padding-right: 40px;
    padding-left: 40px;
}
.img-logo {
    height: 50px;
    margin-top: -16px;
}
.mn-top{
    background-color: #fff;
    padding-top: 63px;
    padding-bottom: 3px;
    text-align: center;
    font-size: 1.1em;
}
.mn-top span{
    color: #666;
    margin-left: 10px;
    padding-bottom: 13px;
}
.mn-top span:hover{
    border-bottom: 3px solid #cc181e;
}
.sidebar.left {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    bottom: 0;
    width: 220px;
    background: #ffffff;
    -webkit-box-shadow: 1px 0px 11px 0px rgba(50, 50, 50, 0.2);
    -moz-box-shadow: 1px 0px 11px 0px rgba(50, 50, 50, 0.2);
    box-shadow: 1px 0px 11px 0px rgba(50, 50, 50, 0.2);
}
.m-t-60 {
    margin-top: 63px;
}
.p-10 {
    padding: 10px;
}
.actv-side {
    background-color: #cc181e;
    color: #fff;
}
.id-ctgr {
    padding: 7px 5px;
    margin-bottom: 3px;
}
.id-ctgr:hover {
    background-color: #222;

}
.id-ctgr:hover i{
    margin-right: 8px;
}
.item-sub{
	background-color: #fff;
    color: #000;
}
.item-sub:hover{
    color: #fff;
}
.style10 {
    padding: 0px 0;
}
.my-sideber{
    height: 890px;
    overflow-y: auto;
}
@media (min-width: 1200px){
.m-left {
    margin-left: 232px;
}
}
.tool-ft{
   cursor: pointer;
   position: fixed;
   bottom: 20px;
   right: 20px;
   display:block;
}
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}
/* VIDEO API CSS */
.video-list-thumbs{}
.video-list-thumbs > li{
    margin-bottom:12px
}
.video-list-thumbs > li:last-child{}
.video-list-thumbs > li > span{
  display:block;
  position:relative;
  background-color: #212121;
  color: #fff;
  padding: 0px;
}
.video-list-thumbs > li > span:hover{
  background-color:#000;
  transition:all 500ms ease;
  box-shadow:0 2px 4px rgba(0,0,0,.3);
  text-decoration:none
}


.video-list-thumbs li h2 {
    font-size: 13px;
		color: #ffffff;
    padding-bottom: 4px !important;
    text-align: center;
    margin: 5px 3px 3px 0;
    line-height:18px;
    overflow:hidden;
    display:-webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-flex:1;
    -webkit-box-orient:vertical;
}
.video-list-thumbs .fa-play-circle-o{
    font-size: 60px;
    opacity: 0.2;
    position: absolute;
    right: 39%;
    top: 31%;
    text-shadow: 0 1px 3px rgba(0,0,0,.5);
}
.video-list-thumbs > li > a:hover .fa-play-circle-o{
  color:#fff;
  opacity:1;
  text-shadow:0 1px 3px rgba(0,0,0,.8);
  transition:all 500ms ease;
}

.video-list-thumbs .time{
    background-color: rgba(0, 200, 240, 0.8);
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  right: 0;
  line-height: 13px;
  padding: 4px;
  position: absolute;
  bottom: 44px;
}

.video-list-thumbs .duration{
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  left: 8px;
  line-height: 13px;
  padding: 4px;
  position: absolute;
  top: 8px;
}
.video-list-thumbs > li > a:hover .duration{
  background: rgba(184, 44, 44, 0.9);
  transition:all 500ms ease;
}
@media (min-width:320px) and (max-width: 480px) {
  .video-list-thumbs .fa-play-circle-o{
    font-size: 35px;
    right: 36%;
    top: 27%;
  }
  .video-list-thumbs h2{
    bottom: 0;
        padding: 3px;
    font-size: 12px;
    height: 22px;
    margin: 8px 0 0;
  }
}
/* END */
@media (max-width: 1200px){
   .sidebar{
      display: none;
   }
}
.active-mn-top{
   border-bottom: 3px solid #cc181e;
   color: #333 !important;
   font-weight: 600;
}
.ads-bg-top{
   margin-bottom: 28px;
}
.title-topic span{
    font-size: 18px;
    -moz-text-transform: uppercase;
    -webkit-text-transform: uppercase;
    text-transform: uppercase;
    color: white;
}
.title-topic{
   margin-bottom: 7px;
}
.pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}
.pace-inactive {
    display: none
}
.pace .pace-progress {
    background: #cc181e;
    position: fixed;
    z-index: 100000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 2px
}

.styleViews {
	color: #ffffff;
	text-align: center;
	font-size: 12px;
}

.styleDescription {
	color: #ffffff;
	font-size: 11px;
}

.tamVideo {
	height: 190px;
}
