.info_cel {
  color: black;
  font-weight: 100;
}

.infoTitleWorking {
  font-size: 16px;
  color: #3B0B0B;
}

.infoNameClient {
  color: #0174DF;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  font-weight: 600;
  word-wrap: break-word;
  text-transform: uppercase;
}

.infoAddress {
  color: black;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}

.carousel {
  height: 243px;
}

.infoBox > img {
  position: absolute !important;
  top: -1px;
  right: 0;
  z-index: 600;
}

.infoBoxContainer {
  background-color: white;
  border: 0px solid #484848 !important;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3) !important;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3) !important;
  margin-bottom: 8px;
  position: relative !important;
  width: 325px;
}

.infoBoxContainer::before {
  border: 8px solid transparent;
  border-bottom: 0;
  border-top-color: #484848;
  bottom: -8px;
  content: " ";
  display: inline-block;
  left: 50%;
  margin-left: -8px;
  position: absolute;
}

.infoBoxContainer::after {
  border: 8px solid transparent;
  border-bottom: 0px;
  border-top-color: white;
  bottom: -8px;
  content: " ";
  display: inline-block;
  left: 50%;
  margin-left: -8px;
  position: absolute;
}

.infoBoxContent,
.infoBoxFooter {
  padding: 8px;
}

h2.infoBoxContentTitle {
  word-wrap: break-word;
  font-size: 19px;
  line-height: 22px;
  font-weight: 700;
}

h3.infoBoxContentLocation {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  font-weight: 700;
  word-wrap: break-word;
  text-transform: uppercase;
}

.infoBoxContent p {
  word-wrap: break-word;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  font-weight: 300;
}

.infoBoxFooter {
  text-align: center;
}

.infoBoxContainer .carousel.carousel-slider {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
