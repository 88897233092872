.btn-highlight-1, .btn-highlight-1 {
  color: #fff;
  background-color:#ac2e2d;
  outline: none;
}

.buttonText {
  color: white;
}

.tittleInfo {
  font-size: 22px;
  color: white;
}

.liInfo {
  padding-bottom : 20px !important;
  font-size: 19px;
}

.list-unordered-alt {
  list-style: none;
  padding: 0px;
}

.type-neutral-11 {
  color: #fff !important;
}
